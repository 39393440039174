import React, { useState } from "react";

import impdays1 from "../banner/impdays/impdays1.jpg";
import impdays2 from "../banner/impdays/impdays2.jpg";
import impdays3 from "../banner/impdays/impdays3.jpg";
import impdays4 from "../banner/impdays/impdays4.jpg";
import impdays5 from "../banner/impdays/impdays5.jpg";
import impdays6 from "../banner/impdays/impdays6.jpg";
import impdays7 from "../banner/impdays/impdays7.jpg";
import impdays8 from "../banner/impdays/impdays8.jpg";
import impdays9 from "../banner/impdays/impdays9.jpg";
import impdays10 from "../banner/impdays/impdays10.jpg";
import impdays11 from "../banner/impdays/impdays11.jpg";
import impdays12 from "../banner/impdays/impdays12.jpg";
import impdays13 from "../banner/impdays/impdays13.jpg";

import festivals1 from "../banner/festivals/festivals1.jpg";
import festivals2 from "../banner/festivals/festivals2.jpg";
import festivals3 from "../banner/festivals/festivals3.jpg";
import festivals4 from "../banner/festivals/festivals4.jpg";
import festivals5 from "../banner/festivals/festivals5.jpg";
import festivals6 from "../banner/festivals/festivals6.jpg";
import festivals7 from "../banner/festivals/festivals7.jpg";
import festivals8 from "../banner/festivals/festivals8.jpg";
import festivals9 from "../banner/festivals/festivals9.jpg";

import festivals11 from "../banner/festivals/festivals11.jpg";
import festivals12 from "../banner/festivals/festivals12.jpg";
import festivals13 from "../banner/festivals/festivals13.jpg";
import festivals14 from "../banner/festivals/festivals14.jpg";
import festivals15 from "../banner/festivals/festivals15.jpg";
import festivals16 from "../banner/festivals/festivals16.jpg";
import festivals17 from "../banner/festivals/festivals17.jpg";
import festivals18 from "../banner/festivals/festivals18.jpg";

import festivals20 from "../banner/festivals/festivals20.jpg";

import festivals23 from "../banner/festivals/festivals23.jpg";
import festivals24 from "../banner/festivals/festivals24.jpg";

import festivals27 from "../banner/festivals/festivals27.jpg";

import festivals30 from "../banner/festivals/festivals30.jpg";
import festivals31 from "../banner/festivals/festivals31.jpg";
import festivals32 from "../banner/festivals/festivals32.jpg";
import festivals33 from "../banner/festivals/festivals33.jpg";
import festivals34 from "../banner/festivals/festivals34.jpg";
import festivals35 from "../banner/festivals/festivals35.jpg";
import festivals36 from "../banner/festivals/festivals36.jpg";
import festivals37 from "../banner/festivals/festivals37.jpg";
import festivals38 from "../banner/festivals/festivals38.jpg";
import festivals39 from "../banner/festivals/festivals39.jpg";
import festivals40 from "../banner/festivals/festivals40.jpg";
import festivals41 from "../banner/festivals/festivals41.jpg";
import festivals42 from "../banner/festivals/festivals42.jpg";
import festivals43 from "../banner/festivals/festivals43.jpg";
import festivals44 from "../banner/festivals/festivals44.jpg";
import festivals45 from "../banner/festivals/festivals45.jpg";
import festivals46 from "../banner/festivals/festivals46.jpg";
import festivals47 from "../banner/festivals/festivals47.jpg";
import festivals48 from "../banner/festivals/festivals48.jpg";
import festivals49 from "../banner/festivals/festivals49.jpg";
import festivals50 from "../banner/festivals/festivals50.jpg";
import festivals51 from "../banner/festivals/festivals51.jpg";
import festivals52 from "../banner/festivals/festivals52.jpg";

import remember1 from "../banner/jayanthi/remember1.jpg";
import remember2 from "../banner/jayanthi/remember2.jpg";
import remember3 from "../banner/jayanthi/remember3.jpg";
import remember4 from "../banner/jayanthi/remember4.jpg";
import remember5 from "../banner/jayanthi/remember5.jpg";
import remember6 from "../banner/jayanthi/remember6.jpg";
import remember7 from "../banner/jayanthi/remember7.jpg";
import remember8 from "../banner/jayanthi/remember8.jpg";
import remember9 from "../banner/jayanthi/remember9.jpg";

import remember11 from "../banner/jayanthi/remember11.jpg";
import remember12 from "../banner/jayanthi/remember12.jpg";
import remember13 from "../banner/jayanthi/remember13.jpg";
import remember14 from "../banner/jayanthi/remember14.jpg";
import remember15 from "../banner/jayanthi/remember15.jpg";
import remember16 from "../banner/jayanthi/remember16.jpg";
import remember17 from "../banner/jayanthi/remember17.jpg";
import remember18 from "../banner/jayanthi/remember18.jpg";
import remember19 from "../banner/jayanthi/remember19.jpg";
import remember20 from "../banner/jayanthi/remember20.jpg";

import remember22 from "../banner/jayanthi/remember22.jpg";
import remember23 from "../banner/jayanthi/remember23.jpg";
import remember24 from "../banner/jayanthi/remember24.jpg";
import remember25 from "../banner/jayanthi/remember25.jpg";
import remember26 from "../banner/jayanthi/remember26.jpg";
import remember27 from "../banner/jayanthi/remember27.jpg";
import remember28 from "../banner/jayanthi/remember28.jpg";
import remember29 from "../banner/jayanthi/remember29.jpg";

import remember32 from "../banner/jayanthi/remember32.jpg";

import remember34 from "../banner/jayanthi/remember34.jpg";
import remember35 from "../banner/jayanthi/remember35.jpg";
import remember36 from "../banner/jayanthi/remember36.jpg";

import remember38 from "../banner/jayanthi/remember38.jpg";
import remember39 from "../banner/jayanthi/remember39.jpg";

import remember40 from "../banner/jayanthi/remember40.jpg";
import remember41 from "../banner/jayanthi/remember41.jpg";
import remember42 from "../banner/jayanthi/remember42.jpg";
import remember43 from "../banner/jayanthi/remember43.jpg";
import remember44 from "../banner/jayanthi/remember44.jpg";

import remember46 from "../banner/jayanthi/remember46.jpg";
import remember47 from "../banner/jayanthi/remember47.jpg";
import remember48 from "../banner/jayanthi/remember48.jpg";
import remember49 from "../banner/jayanthi/remember49.jpg";

import remember51 from "../banner/jayanthi/remember51.jpg";
import remember52 from "../banner/jayanthi/remember52.jpg";
import remember53 from "../banner/jayanthi/remember53.jpg";
import remember54 from "../banner/jayanthi/remember54.jpg";

import remember56 from "../banner/jayanthi/remember56.jpg";
import remember57 from "../banner/jayanthi/remember57.jpg";
import remember58 from "../banner/jayanthi/remember58.jpg";
import remember59 from "../banner/jayanthi/remember59.jpg";

import remember60 from "../banner/jayanthi/remember60.jpg";
import remember61 from "../banner/jayanthi/remember61.jpg";

const rememberData = [
  remember1,
  remember2,
  remember3,
  remember4,
  remember5,
  remember6,
  remember7,
  remember8,
  remember9,

  remember11,
  remember12,
  remember13,
  remember14,
  remember15,
  remember16,
  remember17,
  remember18,
  remember19,
  remember20,

  remember22,
  remember23,
  remember24,
  remember25,
  remember26,
  remember27,
  remember28,
  remember29,

  remember32,

  remember34,
  remember35,
  remember36,

  remember38,
  remember39,
  remember40,
  remember41,
  remember42,
  remember43,
  remember44,

  remember46,
  remember47,
  remember48,
  remember49,

  remember51,
  remember52,
  remember53,
  remember54,

  remember56,
  remember57,
  remember58,
  remember59,
  remember60,
  remember61,
];

const festivalsData = [
  festivals1,
  festivals2,
  festivals3,
  festivals4,
  festivals5,
  festivals6,
  festivals7,
  festivals8,
  festivals9,

  festivals11,
  festivals12,
  festivals13,
  festivals14,
  festivals15,
  festivals16,
  festivals17,
  festivals18,

  festivals20,

  festivals23,
  festivals24,

  festivals27,

  festivals30,
  festivals31,
  festivals32,
  festivals33,
  festivals34,
  festivals35,
  festivals36,
  festivals37,
  festivals38,
  festivals39,
  festivals40,

  festivals41,
  festivals42,
  festivals43,
  festivals44,
  festivals45,
  festivals46,
  festivals47,
  festivals48,
  festivals49,

  festivals50,
  festivals51,
  festivals52,
];

const impdaysData = [
  impdays1,
  impdays2,
  impdays3,
  impdays4,
  impdays5,
  impdays6,
  impdays7,
  impdays8,
  impdays9,
  impdays10,
  impdays11,
  impdays12,
  impdays13,
];

function ImpdaysTdp() {
  return (
    <div>
      <div className="banner-bg">
        <div className="banner-cont-celebrations">
          {impdaysData.map((eachPath) => (
            <img
              className="banner-img-celebrations"
              src={eachPath}
              alt="banner"
            />
          ))}

          {rememberData.map((eachPath) => (
            <img
              className="banner-img-celebrations"
              src={eachPath}
              alt="banner"
            />
          ))}

          {festivalsData.map((eachPath) => (
            <img
              className="banner-img-celebrations"
              src={eachPath}
              alt="banner"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ImpdaysTdp;

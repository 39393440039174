import React, { useEffect, useRef } from "react";

import celebrations1 from "../banner/birthdays/celebrations1.jpg";
import celebrations2 from "../banner/birthdays/celebrations2.jpg";
import celebrations3 from "../banner/birthdays/celebrations3.jpg";
import celebrations4 from "../banner/birthdays/celebrations4.jpg";
import celebrations5 from "../banner/birthdays/celebrations5.jpg";
import celebrations6 from "../banner/birthdays/celebrations6.jpg";
import celebrations7 from "../banner/birthdays/celebrations7.jpg";
import celebrations8 from "../banner/birthdays/celebrations8.jpg";
import celebrations9 from "../banner/birthdays/celebrations9.jpg";

// import celebrations14 from "../banner/birthdays/celebrations14.jpg";

import celebrations16 from "../banner/birthdays/celebrations16.jpg";

import celebrations19 from "../banner/birthdays/celebrations19.jpg";
import celebrations20 from "../banner/birthdays/celebrations20.jpg";

import celebrations23 from "../banner/birthdays/celebrations23.jpg";

import celebrations27 from "../banner/birthdays/celebrations27.jpg";
import celebrations28 from "../banner/birthdays/celebrations28.jpg";
import celebrations29 from "../banner/birthdays/celebrations29.jpg";

import celebrations31 from "../banner/birthdays/celebrations31.jpg";
import celebrations32 from "../banner/birthdays/celebrations32.jpg";
import celebrations33 from "../banner/birthdays/celebrations33.jpg";
import celebrations34 from "../banner/birthdays/celebrations34.jpg";
import celebrations35 from "../banner/birthdays/celebrations35.jpg";
import celebrations36 from "../banner/birthdays/celebrations36.jpg";

// service

import services1 from "../banner/services/services1.jpg";
import services2 from "../banner/services/services2.jpg";
import services3 from "../banner/services/services3.jpg";

import services5 from "../banner/services/services5.jpg";
import services6 from "../banner/services/services6.jpg";
import services7 from "../banner/services/services7.jpg";

// success

import success4 from "../banner/success/success4.jpg";
import success5 from "../banner/success/success5.jpg";
import success6 from "../banner/success/success6.jpg";
import success7 from "../banner/success/success7.jpg";

import success10 from "../banner/success/success10.jpg";
import success11 from "../banner/success/success11.jpg";

import success22 from "../banner/success/success22.jpg";

// welcome

import welcome2 from "../banner/welcome/welcome2.jpg";
import welcome3 from "../banner/welcome/welcome3.jpg";
import welcome4 from "../banner/welcome/welcome4.jpg";
import welcome5 from "../banner/welcome/welcome5.jpg";
import welcome6 from "../banner/welcome/welcome6.jpg";
import welcome10 from "../banner/welcome/welcome10.jpg";
import welcome12 from "../banner/welcome/welcome12.jpg";
import welcome13 from "../banner/welcome/welcome13.jpg";

import welcome16 from "../banner/welcome/welcome16.jpg";
import welcome17 from "../banner/welcome/welcome17.jpg";

import welcome20 from "../banner/welcome/welcome20.jpg";

import welcome24 from "../banner/welcome/welcome24.jpg";
import welcome25 from "../banner/welcome/welcome25.jpg";
import welcome26 from "../banner/welcome/welcome26.jpg";
import welcome27 from "../banner/welcome/welcome27.jpg";
import welcome28 from "../banner/welcome/welcome28.jpg";
import welcome29 from "../banner/welcome/welcome29.jpg";
import welcome30 from "../banner/welcome/welcome30.jpg";

// different sizes banner

// import celebrations141 from "../banner/birthdays/celebrations141.jpg";
// import celebrations142 from "../banner/birthdays/celebrations142.jpg";

import "./BannerTdp.css";

const celebrationsData = [
  celebrations1,
  celebrations2,
  celebrations3,
  celebrations4,
  celebrations5,
  celebrations6,
  celebrations7,
  celebrations8,
  celebrations9,

  //   celebrations14,

  celebrations16,

  celebrations19,
  celebrations20,

  celebrations23,

  celebrations27,
  celebrations28,
  celebrations29,

  celebrations31,
  celebrations32,
  celebrations33,
  celebrations34,
  celebrations35,
  celebrations36,
];

const ServicesData = [
  services1,
  services2,
  services3,
  services5,
  services6,
  services7,
];

const successData = [
  success4,
  success5,
  success6,
  success7,

  success10,
  success11,

  success22,
];

const welcomeData = [
  welcome2,
  welcome3,
  welcome4,
  welcome5,
  welcome6,
  welcome10,
  welcome12,
  welcome13,

  welcome16,
  welcome17,

  welcome20,

  welcome24,
  welcome25,
  welcome26,
  welcome27,
  welcome28,
  welcome29,
  welcome30,
];

const Celebrations = () => {
  return (
    <div className="banner-bg">
      <div className="banner-cont-celebrations">
        {celebrationsData.map((eachPath) => (
          <img
            className="banner-img-celebrations"
            src={eachPath}
            alt="banner"
          />
        ))}

        {welcomeData.map((eachPath) => (
          <img
            className="banner-img-celebrations"
            src={eachPath}
            alt="banner"
          />
        ))}

        {successData.map((eachPath) => (
          <img
            className="banner-img-celebrations"
            src={eachPath}
            alt="banner"
          />
        ))}

        {ServicesData.map((eachPath) => (
          <img
            className="banner-img-celebrations"
            src={eachPath}
            alt="banner"
          />
        ))}
      </div>

      {/* <div className="banner-cont-celebrations">
        <img
          className="banner-img-celebrations-last1"
          src={celebrations141}
          alt="banner"
        />
      </div>
      <div className="banner-cont-celebrations">
        <img
          className="banner-img-celebrations-last2"
          src={celebrations142}
          alt="banner"
        />
      </div> */}
    </div>
  );
};

export default Celebrations;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { FaInstagram, FaLinkedin, FaFacebook, FaTwitter } from "react-icons/fa";

import Person from "../assets/1.jpg";
import img2 from "../assets/2.jpg";
import img5 from "../assets/5.jpg";
import pdf from "../assets/details.pdf";
import raghavendra from "../assets/raghavendra.png";

import slide2 from "../assets/slide2.jpg";

import Header from "../header/header";
import Footer from "../footer/footer";
import HeaderAbout from "../headerAbout/headerAbout";

import bbb from "../assets/bbb (1).jpg";
import yuvaChaithanyaYathra from "../assets/yuvachaithanya-yathra (7).jpg";

import kolluRavindra1 from "../assets/kollu-ravindra1.jpeg";

import specialStatus from "../assets/speshal-status1.jpeg";

import yuvaNestham1 from "./aboutImgs/yuvanestham1.jpeg";
import yuvaNestham2 from "./aboutImgs/yuvanestham2.jpeg";
import yuvaNestham3 from "./aboutImgs/yuvanestham3.jpeg";

import specialStatus1 from "./aboutImgs/speshal-status1.jpeg";
import specialStatus2 from "./aboutImgs/speshal-status2.jpeg";
import specialStatus3 from "./aboutImgs/speshal-status3.jpeg";

import bbb1 from "./aboutImgs/bbb (1).jpg";
import bbb2 from "./aboutImgs/bbb (3).JPG";
import bbb3 from "./aboutImgs/bbb (8).JPG";
import bbb4 from "./aboutImgs/bbb (14).JPG";
import bbb5 from "./aboutImgs/bbb (24).jpg";

import ppyy1 from "./aboutImgs/yuvachaithanya-yathra (1).jpg";
import ppyy2 from "./aboutImgs/yuvachaithanya-yathra (2).jpg";
import ppyy3 from "./aboutImgs/yuvachaithanya-yathra (8).jpg";
import ppyy4 from "./aboutImgs/yuvachaithanya-yathra (28).jpg";

import soloImg from "../assets/solo.JPG";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function AboutMe() {
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <div>
      <HeaderAbout />
      <Header />
      <h3 className="heading-primary u-text-center">About Raghavendra</h3>
      {/* <div className="colored-heading">
        <h1>About Raghavendra</h1>
      </div> */}
      <section className="about-me-main">
        <div className="about-me-main__hero-info">
          <h3>Welcome To My World</h3>
          <h1>Hai I'm Raghavendra</h1>
          <div className="sm-img-hero">
            <img src={raghavendra} />
            <div className="rotate-text"></div>
          </div>
          <div className="about-me-main__hero-info__text-animate">
            <h2>Telugu Desam Party Karyakartha</h2>
          </div>
          <p>
            Raghavendra Ghanjam was born on 04 Feb at Kavali, Nellore district,
            Andhra Pradesh. He is working as "TDP Official Spokesperson & Social
            Media convener for State B.C Cell Andhra Pradesh". He have 15year's
            Experiencs in TDP party as a Senior Member.
          </p>
          <div className="about-me-main__btn-box">
            <a href="mailto:tdprghanjam@gmail.com" className="btn">
              Send Me a Mail
            </a>
            <a
              //  href={pdf} target="_blank"
              className="d-cv"
            >
              Know More
            </a>
          </div>
          <div className="social-media">
            <div className="bg-icon" style={{ "--i": 5 }}>
              <a href="#" style={{ "--i": 1 }}>
                <FaInstagram />
              </a>
              <span></span>
            </div>
            <div className="bg-icon" style={{ "--i": 6 }}>
              <a href="#" target="_blank" style={{ "--i": 2 }}>
                <FaFacebook />
              </a>
              <span></span>
            </div>
            <div className="bg-icon" style={{ "--i": 7 }}>
              <a href="#" style={{ "--i": 3 }}>
                <FaLinkedin />
              </a>
              <span></span>
            </div>
            <div className="bg-icon" style={{ "--i": 8 }}>
              <a href="#" style={{ "--i": 4 }}>
                <FaTwitter />
              </a>
              <span></span>
            </div>
          </div>
        </div>

        <div className="img-hero">
          <img src={raghavendra} />
          <div className="rotate-text"></div>
        </div>
      </section>
      <div className="row">
        <div className="about-me">
          <img className="about-me__img" src={soloImg} />
          <h1>విద్యావేత్త డా. రాఘవేంద్ర... యువతకు మార్గదర్శి</h1>
          <p className="">
            డా. రాఘవేంద్ర .. విద్యావేత్తగా, సామాజిక సంస్కరణాభిలాషిగా ప్రకాశం
            జిల్లాలో మంచి పేరున్న వ్యక్తి. మాటలు కాదు ఏది చెప్పినా చేతల్లో
            చూపాలి, సమాజం మనకేం ఇచ్చినా.. మనం సమాజానికి చేయాల్సింది ఎంతో ఉందని
            నమ్మే మనిషి. హైదరాబాద్ మరియు అమెరికాలో ఉన్నతమయిన స్థాయిలో పనిచేశారు.
            ఈ తరం యువత అంతా అమెరికాలో ఉన్నత ఉద్యోగాలు చేస్తూ, చేతినిండా
            సంపాదిస్తూ, అక్కడే ఉండిపోవాలని భావిస్తారు. కానీ రాఘవేంద్ర ఆలోచన
            అందరికీ భిన్నంగా ఉంది. తన తండ్రి రామయ్య గారి వారసత్వం కొనసాగిస్తూనే,
            లక్షలాదిమంది యువతకు సాయపడాలని భావించారు. అందులో భాగంగానే యువతకు
            దారిచూపారు. ప్రకాశం ఇంజనీరింగ్ కాలేజ్ డైరెక్టర్ గా , గంజాం ఫౌండేషన్
            ఫౌండర్ గా ఎంతోమంది నిరుద్యోగ యువతకు శిక్షణ ఇచ్చి ఉపాధి కల్పించారు.
          </p>
          <p>
            BTECH, MBA, MIE చదువుకున్న విద్యావంతుడు. సమాజం కోసం, యువతకు మంచి
            విద్య, ఉద్యోగ, ఉపాధి అవకాశాల కోసం ప్రకాశం ఇంజనీరింగ్ కాలేజీని
            స్థాపించిన తన తండ్రి రామయ్యగారికి చేదోడు వాదోడుగా నిలుస్తూ
            లక్షలాదిమంది యువతకు మార్గదర్శిగా మారారు. ఈ సమాజం కోసం ఏదైనా
            చెయ్యాలి… రేపటి పౌరుల భవిష్యత్ కోసం ఒక మార్గం చూపాలని తపించారు.
            ఒకవైపు విద్యాసంస్థతో పాటు మరోవైపు సమాజం కోసం రాజకీయాల్లోకి వచ్చి
            రాణిస్తున్నారు. కాలేజీలో ఎయిర్ టెల్ కాల్ సెంటర్ ని ఏర్పాటుచేసి
            నాలుగువేల పైచిలుకు నిరుద్యోగ యువతకు ఉద్యోగ అవకాశాలు కల్పించారు.
            గంజాం ఫౌండేషన్ ని స్థాపించి సేవే ధ్యేయంగా నడుస్తున్న రాఘవేంద్ర
            నిరంతరం శ్రమనే నమ్ముకున్నారు. వెటర్నరీ అంబులెన్స్, 40 వేల పైచిలుకు
            మొక్కలు నాటడం, ప్రభుత్వ పాఠశాలల్లో ఎన్నో ఉచిత రోబోటిక్ శిక్షణలు
            వ్యక్తిత్వ వికాస శిక్షణలు ఉచితంగా ఇవ్వడం జరిగింది. 60కి పైగా రక్తదాన
            శిబిరాలు ఏర్పాటుచేశారు. వ్యక్తిగతంగా 36 సార్లు రక్తదానం చేసి
            ఆదర్శంగా నిలిచారు. ప్రతిభ ఉండి ఉన్నత విద్యకు దూరం అయిన నిరుపేదలకు
            ఉచిత విద్యను అందించారు.
          </p>
          <p>
            టీడీపీ తెలుగు సాంకేతిక నిపుణుల విభాగం ఆర్గనైజింగ్ సెక్రటరీగా
            పనిచేశారు. 2009లో టీడీపీ ఎన్నికల క్యాంపెయినింగ్ కమిటీలో కీలక పాత్ర
            పోషించారు. రాజకీయాల్లోకి వచ్చి ప్రజాప్రతినిధిగా ఎన్నికల్లో నిలబడాలని
            భావించారు. రాఘవేంద్ర లో ఉన్న సేవాగుణం, సంకల్ప బలాన్ని ప్రజలతో పాటుగా
            తెలుగుదేశం పార్టీ కూడా గుర్తించింది. 2014 స్థానిక సంస్థల ఎన్నికలలో
            కందుకూరు ZPTCఅభ్యర్థిగా రాఘవేంద్ర ని ఎన్నికల బరిలో నిలిపింది
            తెలుగుదేశం పార్టీ. పోటీ ఎంత వున్నా.. కంటెంట్ ఉన్నవాడికి కటౌట్ చాలు
            అన్నట్టుగా జనం మెచ్చారు.
          </p>
          <p>
            పార్టీ జెండాతో పాటు రాఘవేంద్ర అజెండా కలిసి వచ్చింది. రాఘవేంద్ర కి
            ఓటర్లు మంచి విజయాన్ని అందించారు. గెలుపు ఇచ్చిన ఉత్సాహంతో అనేక సేవా
            కార్యక్రమాలు చేపట్టారు. 2019 ఎన్నికలకు ముందు 5 పార్లమెంట్ స్థానాలకు
            సేవామిత్ర శిక్షణా కేంద్రాలకు డైరెక్టర్ గా వ్యవహరించడం ద్వారా
            వేలాదిమంది తెలుగుదేశం పార్టీ నాయకులను సుశిక్షితులైన సైనికుల్లా
            తయారుచేయడంలో కీలక పాత్ర పోషించారు. 2021 నుంచి ఐటీడీపీ అధికార
            ప్రతినిధిగా పనిచేస్తున్నారు. 2017, 2019లో రాష్ట్రస్థాయిలో ఉత్తమ
            జెడ్పీటీసీ అవార్డు అందుకున్నారు. రాష్ట్రస్థాయి స్టార్టప్ ఛాలెంజ్
            అవార్డు, అప్పటి గవర్నర్ నరసింహన్ చేతుల మీదుగా ఎన్టీఆర్ ట్రస్ట్ వారి
            ఎన్టీఆర్ సేవా పురస్కారం.. గ్రామీణ ప్రాంతాల్లో వేలాదిమంది నిరుద్యోగ
            యువతకు ఉపాధి అవకాశాలు కల్పించినందుకు మాజీ ఉపరాష్ట్రపతి వెంకయ్యనాయుడు
            గారి చేతుల మీదుగా బెస్ట్ రూరల్ ఎంటర్ ప్రెన్యూర్ అవార్డు
            అందుకున్నారు. జాతీయ స్థాయిలో ఎన్నో సంస్థల్లో ఆయనకు సభ్యత్వాలు
            ఉన్నాయి. అంతర్జాతీయ సదస్సుల్లో పాల్గొని తన ప్రతిభని చాటుకున్నారు.
          </p>
          <p>
            తాజాగా రాఘవేంద్ర జీవితంలో ఒక కొత్త మలుపు ప్రారంభం అయింది. తూర్పు
            రాయలసీమ గ్రాడ్యుయేట్ ఎమ్మెల్సీ ఎన్నికలు… ఉమ్మడి చిత్తూరు, నెల్లూరు,
            ప్రకాశం జిల్లాల అభ్యర్థిగా మన డా.గంజాం రాఘవేంద్ర పోటీలో ఉన్నారు….
            రాఘవేంద్ర మంచితనం, సేవాగుణం, సమస్యలపై స్నందించే తత్వం, యువతకు ఉపాధి
            మార్గాలు గమనించి,పట్టభద్రుల కోసం పోరాడే వ్యక్తిగా ఆయనకు శాసనమండలి
            ఎన్నికల్లో పోటీచేసే అవకాశం కల్పించారు టీడీపీ అధినేత చంద్రబాబునాయుడు
            గారు, నారా లోకేష్ గారు.
          </p>
          <p>
            ఏపీ శాసనమండలికి టీడీపీ బలపరిచిన డా.గంజాం రాఘవేంద్ర మండలిలో
            అడుగుపెడితే నిరుద్యోగులు, పట్టభద్రులకు భరోసా కల్పించడం ఖాయం.
            విజ్ఞానవంతులు, వివేకవంతులైన గ్రాడ్యుయేట్లు, టీచర్లు, ప్రభుత్వ
            ఉద్యోగులు అందరూ మంచి మనసుతో ఆలోచించి మీ మొదటి ప్రాధాన్యత ఓటు వేసి
            రాఘవేంద్ర ని బలపరచాలి. మంచి వారసత్వం కలిగి, ఉత్తమ వ్యక్తిత్వం ఉన్న
            రాఘవేంద్ర లాంటి యువకిశోరాలు మండలిలో ఉండాల్సిన అవసరం ఎంతైనా ఉంది. మీ
            సమస్యలు బాగా తెలిసిన డా.గంజాం రాఘవేంద్ర కి మొదటి ప్రాధాన్యత ఓటేస్తే
            ఆయన ఎమ్మెల్సీగా ఎన్నికై ఆ పదవికి వన్నె తేవడం ఖాయం. ఒక్కసారి
            ఆలోచించండి….మీ ఆలోచనతో భావితరాలకు బాటలు వేయండి.. డా గంజాం .రాఘవేంద్ర
            కు ఓటువేసి గెలిపించండి. పట్టభద్రులారా.. పెద్దమనసుతో ఆలోచించండి..
            జైహింద్…
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutMe;

// <div className="row2" data-aos="fade-left">
//           <div className="col-1-of-22">
//             <h1>Yuva Nestham</h1>
//             <p className="paragraph u-margin-bottom-small">
//               "TDP National president N. Chandrababu Naidu said that NTR proved
//               that a common man can reach great heights".
//             </p>

//             <p className="paragraph u-margin-bottom-small">
//               The Telugu Desam Party (TDP) rank and file paid rich tributes to
//               party’s founder president N.T. Rama Rao on the occasion of his
//               26th death anniversary on Tuesday. TDP Andhra Pradesh State
//               president K. Atchannaidu garlanded the statue of NTR at the party
//               office at Mangalagiri near Vijayawada on Tuesday.
//             </p>
//             <p className="paragraph u-margin-bottom-small">
//               In a statement, TDP National president N. Chandrababu Naidu said
//               that NTR proved that a common man can reach great heights and
//               achieve success. Born in a farmer’s family, NTR brought laurels to
//               the Telugus. He was the uncrowned king in the film industry, and
//               matchless political leader. His journey with a slogan- pride of
//               Telugus was unforgettable. NTR came to power within 9 months of
//               founding the party. NTR has shown the power was not for enjoyment
//               but to serve the people. The power which was limited to only a few
//               sections of the society was brought to the reach of downtrodden.
//               NTR laid foundations for the welfare schemes, he recalled.
//             </p>
//           </div>
//           <div className="col-1-of-22">
//             <Slider {...settings}>
//               <div>
//                 <img className="leaders-slider-imgs" src={yuvaNestham1} />
//               </div>
//               <div>
//                 <img className="leaders-slider-imgs" src={yuvaNestham2} />
//               </div>
//               <div>
//                 <img className="leaders-slider-imgs" src={yuvaNestham3} />
//               </div>

//               {/* <div>
//                 <img className="leaders-slider-imgs" src={yuvaNestham4} />
//               </div>
//               <div>
//                 <img className="leaders-slider-imgs" src={yuvaNestham5} />
//               </div> */}
//             </Slider>
//           </div>
//         </div>

//         <div className="row2" data-aos="fade-right">
//           <div className="col-1-of-22">
//             <Slider {...settings}>
//               <div>
//                 <img className="leaders-slider-imgs" src={specialStatus1} />
//               </div>
//               <div>
//                 <img className="leaders-slider-imgs" src={specialStatus2} />
//               </div>
//               <div>
//                 <img className="leaders-slider-imgs" src={specialStatus3} />
//               </div>

//               {/* <div>
//                 <img className="leaders-slider-imgs" src={yuvaNestham4} />
//               </div>
//               <div>
//                 <img className="leaders-slider-imgs" src={yuvaNestham5} />
//               </div> */}
//             </Slider>
//           </div>
//           <div className="col-1-of-22">
//             <h1 className=" u-margin-bottom-small">
//               Special Status for Andhra Pradesh
//             </h1>
//             <ul>
//               <li>
//                 <FontAwesomeIcon icon={faCheckDouble} /> Official TDP Mettings
//               </li>
//               <li>
//                 <FontAwesomeIcon icon={faCheckDouble} /> Election Campanion
//               </li>
//               <li>
//                 <FontAwesomeIcon icon={faCheckDouble} /> TDP Media Mettings
//               </li>
//             </ul>
//             <p className="paragraph u-margin-bottom-small">
//               He talk about how to use these platforms effectively for outreach
//               and promotion of protest actions. In addition, He give practical
//               advise on how to work around censorship and shadow bans.
//             </p>
//             <p className="paragraph u-margin-bottom-small">
//               Connectivity is among the most significant benefits of social
//               media. It can link countless users at any time, everywhere.
//               Information could be spread globally through social media and its
//               connectedness, making it simple for people to interact with one
//               another. It results in global relationships.
//             </p>
//           </div>
//         </div>

//         <div className="row2" data-aos="fade-left">
//           <div className="col-1-of-22">
//             <h1>Bring Babu Back</h1>
//             <p className="paragraph u-margin-bottom-small">
//               The Bring Babu Back programme was organized under the aegis of
//               TSNV for rallies and meetings with the support of youth in all the
//               mandals of the constituency. Under the aegis of TSNV, the voter
//               registration programme was conducted from the village level to the
//               constituency nthai and new voters were registered. This
//               contributed to the 2014 party's victory in the constituency. As
//               per the instructions of Srinara Lokesh, we have been able to take
//               up the party's membership drive in the constituency and include
//               more people as members.
//             </p>
//           </div>
//           <div className="col-1-of-22">
//             <Slider {...settings}>
//               <div>
//                 <img className="leaders-slider-imgs" src={bbb1} />
//               </div>
//               <div>
//                 <img className="leaders-slider-imgs" src={bbb2} />
//               </div>
//               <div>
//                 <img className="leaders-slider-imgs" src={bbb3} />
//               </div>

//               <div>
//                 <img className="leaders-slider-imgs" src={bbb4} />
//               </div>
//               <div>
//                 <img className="leaders-slider-imgs" src={bbb5} />
//               </div>
//             </Slider>
//           </div>
//         </div>

//         <div className="row2" data-aos="fade-right">
//           <div className="col-1-of-22">
//             <div className="">
//               <Slider {...settings}>
//                 <div>
//                   <img className="leaders-slider-imgs" src={ppyy1} />
//                 </div>
//                 <div>
//                   <img className="leaders-slider-imgs" src={ppyy2} />
//                 </div>
//                 <div>
//                   <img className="leaders-slider-imgs" src={ppyy3} />
//                 </div>

//                 <div>
//                   <img className="leaders-slider-imgs" src={ppyy4} />
//                 </div>
//               </Slider>
//             </div>
//           </div>
//           <div className="col-1-of-22">
//             <h1 className="u-margin-bottom-small">
//               Palle Palliki Yuva Chaithanyayatra
//             </h1>
//             <p className="paragraph">
//               Raghavendra Ghanjam In the 2014 general elections, with the aim of
//               attracting the youth and the poor from the village level to the
//               Telugu Desam Party, we undertook a program called "Palle Palleki
//               Yuva Chaitanya Yatra" and were able to bring about a change in the
//               people by going to every door of the constituency, distributing
//               pamphlets, explaining the governance and schemes of the Telugu
//               Desam Party to every door of the constituency. The programme
//               titled "Yuva Chaitanya Yatra to Palle Palliki", party president
//               Gou! It was brought to the notice of Shri N. Chandrababu Naidu and
//               with his approval and blessings, it was launched for the first
//               time in the state in Udayagiri constituency. The programme was
//               conducted from 04-01-2014 to 04-04-2014.
//             </p>

//             {/* <ul>
//               <li>
//                 <FontAwesomeIcon icon={faCheckDouble} /> To fight against
//                 poverty.
//               </li>
//               <li>
//                 <FontAwesomeIcon icon={faCheckDouble} /> unemployment.
//               </li>
//               <li>
//                 <FontAwesomeIcon icon={faCheckDouble} /> corruption and the
//                 anarchy of the ruling party by mobilizing the youth of AP.
//               </li>
//               <li>
//                 <FontAwesomeIcon icon={faCheckDouble} />
//                 While the padayatra is going on, Lokesh is actively indulging in
//                 hearing out people’s problems by holding face-to-face meetings
//                 with various communities in each constituency. The youth leader
//                 is meeting with farmers, youth, women, Muslims, BCs, SCs, STs,
//                 traders, IT professionals and other communities to understand
//                 their problems.
//               </li>
//             </ul> */}
//           </div>
//         </div>

import React, { useState } from "react";

import problems7 from "../banner/problems/problems7.jpg";
import problems8 from "../banner/problems/problems8.jpg";
import problems9 from "../banner/problems/problems9.jpg";
import problems10 from "../banner/problems/problems10.jpg";
import problems11 from "../banner/problems/problems11.jpg";
import problems12 from "../banner/problems/problems12.jpg";
import problems13 from "../banner/problems/problems13.jpg";
import problems14 from "../banner/problems/problems14.jpg";
import problems15 from "../banner/problems/problems15.jpg";
import problems16 from "../banner/problems/problems16.jpg";
import problems17 from "../banner/problems/problems17.jpg";
import problems18 from "../banner/problems/problems18.jpg";
import problems19 from "../banner/problems/problems19.jpg";

import problems20 from "../banner/problems/problems20.jpg";
import problems21 from "../banner/problems/problems21.jpg";
import problems22 from "../banner/problems/problems22.jpg";
import problems23 from "../banner/problems/problems23.jpg";
import problems24 from "../banner/problems/problems24.jpg";
import problems25 from "../banner/problems/problems25.jpg";
import problems26 from "../banner/problems/problems26.jpg";
import problems27 from "../banner/problems/problems27.jpg";

import problems29 from "../banner/problems/problems29.jpg";

import problems30 from "../banner/problems/problems30.jpg";

const problemsData = [
  problems7,
  problems8,

  problems9,
  problems10,
  problems11,
  problems12,
  problems13,
  problems14,
  problems15,
  problems16,
  problems17,
  problems18,
  problems19,
  problems20,
  problems21,
  problems22,
  problems23,
  problems24,
  problems25,
  problems26,
  problems27,

  problems29,
  problems30,
];

// const problemsData2 = [problems92, problems93, problems94];

function ProblemsTdp() {
  return (
    <div>
      <div className="banner-bg">
        {/* {problemsData2.map((eachPath) => (
          <div className="banner-cont-celebrations">
            <img className="banner-img-welcome" src={eachPath} alt="banner" />
          </div>
        ))} */}
        {/* <div className="banner-cont-celebrations">
          <img className="banner-img-problems" src={problems91} alt="banner" />
        </div> */}
        <div className="banner-cont-celebrations">
          {problemsData.map((eachPath) => (
            <img
              className="banner-img-celebrations"
              src={eachPath}
              alt="banner"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProblemsTdp;

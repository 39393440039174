// import React, { useState } from "react";

// import Celebrations from "./celebrations";
// import Festivals from "./Festivals";
// import ImpdaysTdp from "./ImpdaysTdp";
// import RememberTdp from "./RememberTdp";
// import ServicesTdp from "./ServicesTdp";
// import SuccessTdp from "./SuccessTdp";
// import WelcomeTdp from "./WelcomeTdp";

// function BannerTdp() {
//   return (
//     <div>
//       <Celebrations />
//       <Festivals />
//       <ImpdaysTdp />
//       <RememberTdp />
//       <ServicesTdp />
//       <SuccessTdp />
//       <WelcomeTdp />
//     </div>
//   );
// }

// export default BannerTdp;

import React, { useState, useRef, useEffect } from "react";

import Celebrations from "./celebrations";

import ImpdaysTdp from "./ImpdaysTdp";

import ProblemsTdp from "./ProblemsTdp";
import HeaderAbout from "../components/headerAbout/headerAbout";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";

function BannerTdp() {
  const [selectedButton, setSelectedButton] = useState(0);

  const onAllSelectImage = () => {
    setSelectedButton(0);
  };

  const onEduaction = () => {
    setSelectedButton(1);
  };

  const onDonationsImages = () => {
    setSelectedButton(3);
  };

  const onAwardsImages4 = () => {
    setSelectedButton(8);
  };

  return (
    <div>
      <HeaderAbout />
      <Header />
      <div className="u-margin-top-small">
        <div>
          <div className="gallery-sub-head-cont">
            <div>
              <button
                className={
                  selectedButton === 0
                    ? "gallery-sub-head-select"
                    : "gallery-sub-head-unselect"
                }
                onClick={onAllSelectImage}
              >
                ALL
              </button>
            </div>
            <div>
              <button
                className={
                  selectedButton === 1
                    ? "gallery-sub-head-select"
                    : "gallery-sub-head-unselect"
                }
                onClick={onEduaction}
              >
                LEADERS
              </button>
            </div>

            <div>
              <button
                className={
                  selectedButton === 3
                    ? "gallery-sub-head-select"
                    : "gallery-sub-head-unselect"
                }
                onClick={onDonationsImages}
              >
                IMPORTANT DAYS
              </button>
            </div>

            <div>
              {" "}
              <button
                className={
                  selectedButton === 8
                    ? "gallery-sub-head-select"
                    : "gallery-sub-head-unselect"
                }
                onClick={onAwardsImages4}
              >
                PUBLIC MESSAGE
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="new-update-bg ">
        {selectedButton === 0 && (
          <div>
            <Celebrations />

            <ImpdaysTdp />
          </div>
        )}

        {selectedButton === 1 && (
          <div>
            <Celebrations />
          </div>
        )}

        {selectedButton === 3 && (
          <div>
            <ImpdaysTdp />
          </div>
        )}

        {selectedButton === 8 && (
          <div>
            <ProblemsTdp />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default BannerTdp;
